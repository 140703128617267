button > .MuiIconButton-label > .material-icons {
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: #a1a5b7;
  background-color: #f5f8fa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.3em + 1rem + 1px);
  width: calc(1.3em + 1rem + 1px);
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.325rem;
}
button > .MuiIconButton-label > .material-icons:hover {
  color: #009ef7;
}

.MuiIconButton-root:hover {
  background: none !important;
  background-color: none !important;
}

.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span {
  background-color: #e8fff3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(1) > span > span:hover {
  background-color: #c9ffe3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span {
  background-color: #fff5f8 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(2) > span > span:hover {
  background-color: #ffd6e3 !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span {
  background-color: #f1faff !important;
  border-radius: 50%;
}
.MuiTable-root > tbody > tr > td > div > :nth-child(3) > span > span:hover {
  background-color: #f1faff !important;
  color: #009ef7;
  border-radius: 50%;
}
