.container1 {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  /* background-color: #e2e8f0;     */
}
.main-body {
  padding: 15px;
}
.assignedRole {
  display: flex;
}
.assignedRole > div {
  width: 50%;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-radius: 5rem;
  padding: 0.2rem;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to right, #00c6ff, #0072ff);
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100% !important;
}
.shadow-none {
  box-shadow: none !important;
}

@media only screen and (max-width: 400px) {
  .assignedRole {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .assignedRole {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .assignedRole {
    display: flex;
    justify-content: center;
  }
  .assignedRole > div {
    padding: 0;
    border-radius: 10%;
    display: flex;
    justify-content: center;
  }
}
