body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* .cardsForHome{
  display: flex;
  justify-content: space-around;
  flex-wrap:wrap ;


} */
.cards .feature_item {
  width: 12rem;
}

/* SignIN Page CSS */

.bg {
  background-repeat: no-repeat;
  position: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.back-ground-color {
  background: #a1ffce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.col-3 {
  float: left;
  width: 27.33%;
  margin: 10px 3%;
  position: relative;
}

.effect-19,
.effect-20,
.effect-21 {
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
}

.effect-19 ~ .focus-border:before,
.effect-19 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  width: 0;
  height: 2px;

  border-radius: 5px;
  background-color: rgb(124, 204, 191);
  transition: 0.4s;
}
.effect-19 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  border-radius: 5px;
}
.effect-19 ~ .focus-border i:before,
.effect-19 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  /* background-color: #117fec; */
  border-radius: 5px;
  background-color: rgb(124, 204, 191);
  transition: 0.6s;
}
.effect-19 ~ .focus-border i:after {
  left: auto;
  right: 0;
}
.effect-19:focus ~ .focus-border:before,
.effect-19:focus ~ .focus-border:after,
.has-content.effect-19 ~ .focus-border:before,
.has-content.effect-19 ~ .focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
  border-radius: 5px;
}
.effect-19:focus ~ .focus-border i:before,
.effect-19:focus ~ .focus-border i:after,
.has-content.effect-19 ~ .focus-border i:before,
.has-content.effect-19 ~ .focus-border i:after {
  top: -1px;
  height: 100%;
  transition: 0.6s;
}
.effect-19 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-19:focus ~ label,
.has-content.effect-19 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  /* color: black; */
  color: rgb(124, 204, 191);
  transition: 0.3s;
}
/* .input {
  border: 1px solid grey;
}
.input-label-area {
  margin-left: 10px !important;
}

.Form-label {
  float: left !important;
  padding: 5px 0rem !important;
  font-size: large;
}
.Form-Icon {
  margin: 1rem;
} */
.tabs {
  background-color: #000;
  height: 10%;
  /* width: 50%; */
}
.resolved-tab {
  background-color: red;
}
.underporcess-tab {
  background-color: #0072ff;
  display: flex;
  justify-content: center;
}

/* complain cards dates css */
.Cards-Dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* SignIN Page CSS */

/* Complain css */

/* Complain Section CSS */

.Comaplin-Cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Comaplin-Cards .C-Card {
  width: 280px;
  height: 290px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 1rem;
  /* background: linear-gradient(to right, #be26e4, #18ecde); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+  border-radius: 10px; */
  box-shadow: 10px 10px 10px black;
  overflow-wrap: anywhere;
  border: #222222 1px solid;
  border-radius: 0.5rem;
}

.C-Card-Info {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: white;
  background: linear-gradient(to right, #0b0e0b, #0f0f0f);
  height: 26vh;
}
.C-Card-Info > div {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.Card-Data {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* .C-Card-Info >div>h2+h5{
 background-color: white;
 color: black;
 border-radius: 15%;
 padding: 0.5rem;
 margin-top: 2rem;
} */

.C-Card-Info > div + div > h4 + h4 {
  float: right;
}

.C-Card-Msg {
  padding: 1rem;
  /* background: linear-gradient(to right, #be26e4, #18ecde); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: black;
}
/* .Name{
  display: flex;
  justify-content: space-between;
}
.c-number{
  font-size: 12px;
}
.dueDate-main{
  display: flex;
  justify-content: space-between;
}
.assign-to-main{
  display: flex;
  justify-content: space-between;
}
.closed-date-main{
  display: flex;
  justify-content: space-between;
}
.comp-main-div{
  display: flex;
  justify-content: space-between;
} */
.select-search {
  margin-top: 5px;
}

.cards-under-process {
  display: flex;
  flex-wrap: wrap;
  width: 45%;
  justify-content: center;
  align-items: center;
}
.card-middle-line {
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.cards-resolved {
  display: flex;
  flex-wrap: wrap;
  width: 45%;
  justify-content: center;
  align-items: center;
}
.due-date {
  font-size: 12px;
}
.Closed-Date {
  font-size: 12px;
}
.top-main {
  display: flex;
  justify-content: space-between;
}

.username {
  font-size: 12px;
}

.user-assiged-name {
  font-size: 12px;
}

.relatedTo {
  font-size: 12px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.related-To {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
}
.related-To-item {
  color: black;
  font-size: 14px;
  font-weight: bold;
  background-color: darkgrey;
  border-radius: 5px;
}
.under-process {
  color: #fa3232;
  font-weight: bolder;
  font-size: 12px;
}
.C-button {
  background-color: transparent;
  font-size: 12px;
  border-radius: 20%;
  cursor: pointer;
  float: right;
}

/* .C-button:hover {
  background-color: rgb(136, 134, 131);
} */
.issue-btn {
  background-color: black;
}

.btn-comp {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* complain css end */

/* Media Queries */
@media only screen and (max-width: 400px) {
  .resp-form-container {
    margin-left: auto;
    margin-right: auto;
  }
  .resp-font-size {
    font-size: 11px !important;
  }
  .resp-for-mobile {
    max-width: 30% !important;
  }
  .resp-modal-for-mobile {
    width: 95% !important;
  }
  .Form-label {
    font-size: medium;
  }
  .Form-Icon {
    display: table-cell !important;
  }
  .card-body {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .resp-for-tab {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .resp-for-mobile {
    font-size: 44px !important;
  }
  .resp-form-container {
    width: 60% !important;
  }
  .resp-input {
    height: 45px;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .Form-label {
    font-size: medium !important;
  }
  .card-body {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .main-container23 {
    height: 100vh !important;
  }
}
@media only screen and (max-width: 1024px) {
  .bg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-paper {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .Form-label {
    font-size: 30px;
  }

  .resp-for-tab {
    width: 80% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .resp-for-mobile {
    font-size: 44px !important;
  }
  .resp-form-container {
    width: 60% !important;
  }
  .resp-input {
    height: 55px;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .home_banner_area .home_banner_area_h {
    height: 100vh !important;
  }
  /* .main-container23{
    height: 100% !important;
  } */
}

.relatedTo {
  white-space: pre;
}
/* Media Queries */

/* Landing Page css */
.main-container {
  height: auto;
}
.text-Card {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

/*# sourceMappingURL=style.css.map */
@media (max-width: 1619px) {
  /* Main Menu Area css
============================================================================================ */
  .header_area .abc .search {
    margin-left: 40px;
  }
  /* End Main Menu Area css
============================================================================================ */
}
@media (max-width: 1300px) {
  .header_area .abc .abcd .abcd-item.submenu ul {
    left: auto;
    right: 0px;
  }
}

@media (max-width: 420px) {
  .feature_item p {
    display: none;
  }
  .cards .feature_item {
    width: 140px;
  }
}

/* @media (max-width: 1199px) {
  .container1{
    height: 100%;
  }
 .card-body{
   margin-top: 5rem;
   margin-bottom: 5rem;
 }

  .header_area .abc .abcd .abcd-item {
    margin-right: 28px;
  }
  .home_banner_area .banner_inner {
    padding: 100px 0px;
  }
 
} */
@media (max-width: 1366px) {
  .header_area .abc .abcd .abcd-item .abcd-link {
    line-height: 40px;
    margin-right: 0px;
    display: block;
    border-bottom: 1px solid #ededed33;
    border-radius: 0px;
  }
  .header_area .abc .search {
    margin-left: 0px;
  }
  .header_area .abc-collapse {
    max-height: 340px;
    overflow-y: scroll;
  }
  .header_area .abc .abcd .abcd-item.submenu ul .abcd-item .abcd-link {
    padding: 0px 15px;
  }
  .header_area .abc .abcd .abcd-item {
    margin-right: 0px;
  }
  .home_banner_area .banner_inner .banner_content .banner_map_img {
    display: none;
  }
  /* End Main Menu Area css
============================================================================================ */
  /* Blog page Area css
============================================================================================ */
  .categories_post img {
    width: 100%;
  }
  .categories_post {
    max-width: 360px;
    margin: 0 auto;
  }
  .blog_categorie_area .col-lg-4 {
    margin-top: 30px;
  }
  .blog_area {
    padding-bottom: 80px;
  }
  .single-post-area .blog_right_sidebar {
    margin-top: 30px;
  }
  /* End Blog page Area css
============================================================================================ */

  /* Contact Page Area css
============================================================================================ */
  .contact_info {
    margin-bottom: 50px;
  }
  /* End Contact page Area css
============================================================================================ */
  /* Footer Area css
============================================================================================ */
  .footer-area .col-sm-6 {
    margin-bottom: 30px;
  }
  /* End End Footer Area css
============================================================================================ */
  .home_banner_area {
    background-size: cover;
    margin-bottom: 0px;
  }
  .home_banner_area .banner_inner .banner_content h2 {
    /* margin-top: 30px; */
  }
  .feature_inner {
    margin-bottom: -30px;
  }
  .feature_inner .feature_item {
    margin-bottom: 30px;
  }
  .interior_inner .interior_text {
    margin-top: 30px;
  }
  .interior_two .interior_inner .interior_text {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .price_item {
    max-width: 400px;
    margin: 0px auto 30px;
  }
  .l_news_item {
    max-width: 360px;
    margin: 0px auto 30px;
  }
  .latest_news_inner {
    margin-bottom: -30px;
  }
  /* .logo_h {
    width: 250px !important;
  } */
  /* for small screen scrolling */
  .main-container {
    height: auto;
  }
  /* .home_banner_area .home_banner_area_h{
    height: 100vh;

  } */
}
/* media queries for complain */
@media (max-width: 1290px) {
  .issue-btn {
    margin-top: 10rem;
  }
}

/* for ipad pro hight */

@media (height: 1366px) {
  .main-container {
    height: 100vh;
  }
}
@media (max-width: 991px) {
  /* Main Menu Area css
============================================================================================ */

  .header_area .abc .abcd .abcd-item .abcd-link {
    line-height: 40px;
    margin-right: 0px;
    display: block;
    border-bottom: 1px solid #ededed33;
    border-radius: 0px;
  }
  .header_area .abc .search {
    margin-left: 0px;
  }
  .header_area .abc-collapse {
    max-height: 340px;
    overflow-y: scroll;
  }
  .header_area .abc .abcd .abcd-item.submenu ul .abcd-item .abcd-link {
    padding: 0px 15px;
  }
  .header_area .abc .abcd .abcd-item {
    margin-right: 0px;
  }
  .home_banner_area .banner_inner .banner_content .banner_map_img {
    display: none;
  }
  /* End Main Menu Area css
============================================================================================ */
  /* Blog page Area css
============================================================================================ */
  .categories_post img {
    width: 100%;
  }
  .categories_post {
    max-width: 360px;
    margin: 0 auto;
  }
  .blog_categorie_area .col-lg-4 {
    margin-top: 30px;
  }
  .blog_area {
    padding-bottom: 80px;
  }
  .single-post-area .blog_right_sidebar {
    margin-top: 30px;
  }
  /* End Blog page Area css
============================================================================================ */

  /* Contact Page Area css
============================================================================================ */
  .contact_info {
    margin-bottom: 50px;
  }
  /* End Contact page Area css
============================================================================================ */
  /* Footer Area css
============================================================================================ */
  .footer-area .col-sm-6 {
    margin-bottom: 30px;
  }
  /* End End Footer Area css
============================================================================================ */
  .home_banner_area {
    background-size: cover;
    margin-bottom: 0px;
  }
  .home_banner_area .banner_inner .banner_content h2 {
    /* margin-top: 30px; */
  }
  .feature_inner {
    margin-bottom: -30px;
  }
  .feature_inner .feature_item {
    margin-bottom: 30px;
  }
  .interior_inner .interior_text {
    margin-top: 30px;
  }
  .interior_two .interior_inner .interior_text {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .price_item {
    max-width: 400px;
    margin: 0px auto 30px;
  }
  .l_news_item {
    max-width: 360px;
    margin: 0px auto 30px;
  }
  .latest_news_inner {
    margin-bottom: -30px;
  }
  .logo_h {
    width: 150px !important;
  }
  /* for small screen scrolling */
  .main-container {
    height: auto;
  }
}
.container-fluid {
  height: 88px;
}
@media (max-width: 320px) {
  .bg-transparent {
    padding: 0px;
  }
  .UserRoleRadio {
    font-size: 11px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 380px) {
  .bg-transparent {
    padding: 0px;
  }
  .UserRoleRadio {
    font-size: 13px !important;
  }
}

@media (max-width: 361px) {
  .bg-transparent {
    padding: 0px;
  }
  .UserRoleRadio {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .bg-transparent {
    padding: 0px;
  }
  .container-fluid {
    /* background-color: #15aabb; */
  }

  .UserRoleRadio {
    font-size: 15px;
  }

  /* Main Menu Area css
============================================================================================ */
  .header_area .abc-collapse {
    max-height: 250px;
  }
  /* End Main Menu Area css
============================================================================================ */

  /* Home Banner Area css
============================================================================================ */
  .home_banner_area .banner_inner .banner_content {
    padding: 30px 15px;
    /* padding: 0px 15px; */
    margin-top: 0px;
  }
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 24px;
  }
  /* End Home Banner Area css
============================================================================================ */
  .banner_area .banner_inner .banner_content h2 {
    font-size: 32px;
  }
  /* Blog Page Area css
============================================================================================ */
  .comments-area .thumb {
    margin-right: 10px;
  }

  /* End Blog Page Area css
============================================================================================ */
  .home_banner_area {
    min-height: 350px;
  }
  .home_banner_area .banner_inner {
    min-height: 350px;
  }
  .testi_item .media {
    display: block;
  }
  .testi_item .media .d-flex {
    padding-right: 0px;
  }
  .testi_item .media .d-flex img {
    width: auto;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .bg-transparent {
    padding: 0px;
  }
  .UserRoleRadio {
    font-size: 13px;
  }
  .container-fluid {
    /* background-color: #15aabb; */
  }
  .home_banner_area .home_banner_area_h {
    height: 100% !important;
  }
}
@media (max-width: 1225px) {
  .bg-transparent {
    padding: 0px;
  }
  .UserRoleRadio {
    font-size: 13px;
  }
  .container-fluid {
    /* background-color: #15aabb; */
    height: 90px;
  }
  .main_title {
    margin-top: 2.5rem;
  }
  .logo_h {
    width: 160px;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.header_area {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-transition: background 0.4s, all 0.3s linear;
  -o-transition: background 0.4s, all 0.3s linear;
  transition: background 0.4s, all 0.3s linear;
}

.header_area .abc {
  background: transparent;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
}

.header_area .abc .abcd .abcd-item {
  margin-right: 45px;
}

.header_area .abc .abcd .abcd-item .abcd-link {
  font: 600 12px/100px "Open Sans", sans-serif;
  text-transform: uppercase;
  color: #fff;
  padding: 0px;
  display: inline-block;
}

.header_area .abc .abcd .abcd-item .abcd-link:after {
  display: none;
}

.header_area .abc .abcd .abcd-item.submenu {
  position: relative;
}

.header_area .abc .abcd .abcd-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}

@media (min-width: 992px) {
  .header_area .abc .abcd .abcd-item.submenu ul {
    position: absolute;
    top: 120%;
    left: 0px;
    min-width: 200px;
    text-align: left;
    opacity: 0;
    -webkit-transition: all 300ms ease-in;
    -o-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
    box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
  }
}

.header_area .abc .abcd .abcd-item.submenu ul:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 24px;
  top: 45px;
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.header_area .abc .abcd .abcd-item.submenu ul .abcd-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 1px solid #ededed;
  margin-left: 0px;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.header_area .abc .abcd .abcd-item.submenu ul .abcd-item .abcd-link {
  line-height: 45px;
  color: #222222;
  padding: 0px 30px;
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  display: block;
  margin-right: 0px;
}

.header_area .abc .abcd .abcd-item.submenu ul .abcd-item:last-child {
  border-bottom: none;
}
/* 
.header_area .abc .abcd .abcd-item.submenu ul .abcd-item:hover .abcd-link {
  background-image: -moz-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -webkit-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -ms-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  color: #fff;
} */

/* @media (min-width: 992px) {
  .header_area .abc .abcd .abcd-item.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
}

.header_area .abc .abcd .abcd-item.submenu:hover ul .abcd-item {
  margin-top: 0px;
} */

.header_area .abc .abcd .abcd-item:last-child {
  margin-right: 0px;
}

.header_area .abc .search {
  font-size: 12px;
  line-height: 60px;
  display: inline-block;
  color: #222222;
  margin-left: 80px;
}

.header_area .abc .search i {
  font-weight: 600;
}

.header_area.abc_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  background-image: -moz-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -webkit-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -ms-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  -webkit-transform: translateY(70px);
  -ms-transform: translateY(70px);
  transform: translateY(70px);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease,
    -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

.header_area.abc_fixed .main_menu .abc .abcd .abcd-item .abcd-link {
  line-height: 70px;
}

@media (max-width: 480px) {
  /* Main Menu Area css
============================================================================================ */
  .header_area .abc-collapse {
    max-height: 250px;
  }
  /* End Main Menu Area css
============================================================================================ */

  /* Home Banner Area css
============================================================================================ */
  .home_banner_area .banner_inner .banner_content {
    padding: 30px 15px;
    margin-top: 0px;
  }
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 24px;
  }
  /* End Home Banner Area css
============================================================================================ */
  .banner_area .banner_inner .banner_content h2 {
    font-size: 32px;
  }
  /* Blog Page Area css
============================================================================================ */
  .comments-area .thumb {
    margin-right: 10px;
  }

  /* End Blog Page Area css
============================================================================================ */
  .home_banner_area {
    min-height: 350px;
  }
  .home_banner_area .banner_inner {
    min-height: 350px;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  z-index: 1;
  background: url("./assets/background (2).jpg") no-repeat scroll center center;
  background-size: cover !important;
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-canvas {
  background: url("./assets/background (2).jpg") no-repeat scroll center center;
  background-size: cover !important;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
section .banner_inner {
  height: 100vh;
  /* overflow: hidden; */
  width: 100%;
  /* padding: 170px 0px 140px 0px; */
  padding: 90px 0px 140px 0px;
}
.home_banner_area .home_banner_area_h {
  height: 100vh;
}

.home_banner_area .banner_inner .banner_content {
  color: #fff;
}

.home_banner_area .banner_inner .banner_content .col-lg-9 {
  vertical-align: middle;
  -ms-flex-item-align: center;
  align-self: center;
}

.home_banner_area .banner_inner .banner_content h2 {
  font-size: 60px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
  margin-top: -30px;
}

.home_banner_area .banner_inner .banner_content p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  max-width: 450px;
  margin-bottom: 40px;
  font-size: 14px;
}

.home_banner_area .banner_inner .banner_content .banner_map_img {
  text-align: right;
}

@media (max-width: 767px) {
  .home_banner_area {
    min-height: 500px;
  }
  .home_banner_area .banner_inner {
    min-height: 500px;
  }
  .home_banner_area .banner_inner .banner_content {
    margin-top: 0px;
  }
  /* Blog Page Area css
============================================================================================ */
  .blog_banner .banner_inner .blog_b_text h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .blog_info.text-right {
    text-align: left !important;
    margin-bottom: 10px;
  }
  /* End Blog Page Area css
============================================================================================ */
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 30px;
  }
  .home_banner_area .banner_inner .banner_content p br {
    display: none;
  }
  .home_banner_area .banner_inner .banner_content h3 span {
    line-height: 45px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  /* Footer Area css
============================================================================================ */
  .footer-bottom {
    text-align: center;
  }
  .footer-bottom .footer-social {
    text-align: center;
    margin-top: 15px;
  }
  /* End End Footer Area css
============================================================================================ */
}

@media (max-width: 575px) {
  .main-container {
    height: auto;
  }
  /* Home Banner Area css
============================================================================================ */
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 28px;
  }
  .home_banner_area {
    min-height: 500px;
  }
  .home_banner_area .banner_inner {
    height: 100% !important;
  }
  .blog_banner .banner_inner .blog_b_text {
    margin-top: 38px;
  }
  .home_banner_area .banner_inner .banner_content img {
    display: none;
  }
  .home_banner_area .banner_inner .banner_content h5 {
    margin-top: 0px;
  }
  /* End Home Banner Area css
============================================================================================ */
  .p_120 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .main_title h2 {
    font-size: 25px;
    color: #fff;
  }
  /* Elements Area css
============================================================================================ */
  .sample-text-area {
    padding: 70px 0 70px 0;
  }
  .generic-blockquote {
    padding: 30px 15px 30px 30px;
  }
  /* End Elements Area css
============================================================================================ */

  /* Blog Page Area css
============================================================================================ */
  .blog_details h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .blog_banner .banner_inner .blog_b_text h2 {
    font-size: 28px;
    line-height: 38px;
  }
  /* End Blog Page Area css
============================================================================================ */
}

.blog_banner .banner_inner {
  background: #04091e;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 780px;
  z-index: 1;
}

.blog_banner .banner_inner .overlay {
  /* background: url(../img/banner/banner-2.jpg) no-repeat scroll center center; */
  opacity: 0.5;
  height: 125%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.blog_banner .banner_inner .blog_b_text {
  max-width: 700px;
  margin: auto;
  color: #fff;
  margin-top: 40px;
}

.blog_banner .banner_inner .blog_b_text h2 {
  font-size: 60px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 66px;
  margin-bottom: 15px;
}

.blog_banner .banner_inner .blog_b_text p {
  font-size: 16px;
  margin-bottom: 35px;
}

.blog_banner .banner_inner .blog_b_text .white_bg_btn {
  line-height: 42px;
  padding: 0px 45px;
}

.banner_area {
  position: relative;
  z-index: 1;
  min-height: 400px;
  background-image: -moz-linear-gradient(
    0deg,
    rgba(188, 43, 255, 0.8) 0%,
    rgba(114, 73, 251, 0.8) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgba(188, 43, 255, 0.8) 0%,
    rgba(114, 73, 251, 0.8) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgba(188, 43, 255, 0.8) 0%,
    rgba(114, 73, 251, 0.8) 100%
  );
}

.banner_area .banner_inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 400px;
  z-index: 1;
}

.banner_area .banner_inner .banner_content {
  margin-top: 85px;
}

.banner_area .banner_inner .banner_content h2 {
  color: #fff;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
  font-weight: 500;
}

.banner_area .banner_inner .banner_content .page_link a {
  font-size: 14px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-right: 32px;
  position: relative;
  text-transform: uppercase;
}

.banner_area .banner_inner .banner_content .page_link a:before {
  content: "\f0da";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner_area .banner_inner .banner_content .page_link a:last-child {
  margin-right: 0px;
}

.banner_area .banner_inner .banner_content .page_link a:last-child:before {
  display: none;
}

/* Main Title Area css
============================================================================================ */
.main_title {
  text-align: center;
  /* margin-bottom: 75px; */
  margin-bottom: 40px;
}

.main_title h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  /* color: #222222; */
  color: #fff;
  font-weight: 600;
  margin-bottom: 15px;
}

.main_title p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  /* color: #777777; */
  color: #fff;

  margin-bottom: 0px;
  max-width: 570px;
  font-weight: normal;
  margin: auto;
}

.main_title.white h2 {
  color: #fff;
}

.main_title.white p {
  color: #fff;
  opacity: 0.6;
}

/* End Main Title Area css
============================================================================================ */

/* Feature Area css
============================================================================================ */
.feature_item {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
}

.feature_item img {
  margin-bottom: 35px;
}

.feature_item h4 {
  color: #222222;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 15px;
}

.feature_item p {
  margin-bottom: 0px;
}

/* End Feature Area css
============================================================================================ */

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Main Button Area css
============================================================================================ */
.main_btn {
  width: 100%;
  padding: 0px 45px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bc2bff),
    color-stop(51%, #7249fb),
    to(#bc2bff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-image: -o-linear-gradient(
    left,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-image: linear-gradient(
    to right,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
}

.main_btn:hover {
  background-position: right center;
  color: #fff;
}

.main_btn2 {
  display: inline-block;
  background: #f9f9ff;
  padding: 0px 45px;
  color: #222222;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 48px;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #dddddd;
  cursor: pointer;
  -webkit-transition: color 0ms linear;
  -o-transition: color 0ms linear;
  transition: color 0ms linear;
}

.main_btn2:hover {
  background-image: -moz-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -webkit-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -ms-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
  color: #fff;
}

.main_btn_disabled {
  display: inline-block;
  background: #dddddd;
  padding: 0px 45px;
  color: #222222;
  width: 100%;
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  text-align: center;
  /* color: #fff; */
  font-size: 13px;
  /* font-size: 9px; */
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  border-radius: 5px;
}

.main_btn_disabled:hover {
  background: #dddddd;
  padding: 0px 45px;
  color: #222222;
}

.submit_btn {
  width: auto;
  display: inline-block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bc2bff),
    color-stop(51%, #7249fb),
    to(#bc2bff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f1295b 0%,
    #7249fb 51%,
    #f1591c 100%
  );
  background-image: -o-linear-gradient(
    left,
    #bc2bff 0%,
    #ee6d17 51%,
    #bc2bff 100%
  );
  background-image: linear-gradient(
    to right,
    #43d0f7 0%,
    #f8bd7d 51%,
    #fd1313 100%
  );
  background-size: 200% auto;
  padding: 0px 30px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.submit_btn:hover {
  background-position: right center;
  color: #fff;
}

/* Landing Page css */

/* Cards on Hover Effect CSS */
.hvr-shadow-radial {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.hvr-shadow-radial:before,
.hvr-shadow-radial:after {
  pointer-events: none;
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  height: 5px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.hvr-shadow-radial:before {
  bottom: 100%;
  background: -webkit-radial-gradient(
    50% 200%,
    ellipse,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at 50% 200%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:after {
  top: 100%;
  background: -webkit-radial-gradient(
    50% -50%,
    ellipse,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at 50% -50%,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.hvr-shadow-radial:hover:before,
.hvr-shadow-radial:focus:before,
.hvr-shadow-radial:active:before,
.hvr-shadow-radial:hover:after,
.hvr-shadow-radial:focus:after,
.hvr-shadow-radial:active:after {
  opacity: 1;
}

/* Cards on Hover Effect CSS */

/* for user form button */
.test-TwoBtn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.dialog-btn {
  /* width: 100%; */
  padding: 0px 20px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bc2bff),
    color-stop(51%, #7249fb),
    to(#bc2bff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-image: -o-linear-gradient(
    left,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-image: linear-gradient(
    to right,
    #bc2bff 0%,
    #7249fb 51%,
    #bc2bff 100%
  );
  background-size: 200% auto;
  z-index: 2;
  display: inline-block;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  text-align: center;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
}

.dialog-btn:hover {
  background-position: right center;
  /* color: #fff; */
  color: #000 !important;
}
.tick-btn-abc {
  width: auto;
  display: inline-block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bc2bff),
    color-stop(51%, #7249fb),
    to(#bc2bff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #15bb15 0%,
    #15bb15 51%,
    #15bb15 100%
  );
  background-image: -o-linear-gradient(
    left,
    #15bb15 0%,
    #15bb15 51%,
    #15bb15 100%
  );
  background-image: linear-gradient(
    to right,
    #15bb15 0%,
    #15bb15 51%,
    #15bb15 100%
  );
  background-size: 200% auto;
  padding: 0px 30px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.close-btn-abc {
  width: auto;
  display: inline-block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#bc2bff),
    color-stop(51%, #7249fb),
    to(#bc2bff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f8144d 0%,
    #f8144d 51%,
    #f8144d 100%
  );
  background-image: -o-linear-gradient(
    left,
    #f8144d 0%,
    #f8144d 51%,
    #f8144d 100%
  );
  background-image: linear-gradient(
    to right,
    #f8144d 0%,
    #f8144d 51%,
    #f8144d 100%
  );
  background-size: 200% auto;
  padding: 0px 30px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

/* for add user button */
.addUserBtn:hover {
  background-color: darkgreen !important;
  color: #fff;
}

/* Home latest Blog Area css
============================================================================================ */
.l_news_item {
  background: #f9f9ff;
  padding: 60px 30px;
  border-radius: 5px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.l_news_item .date {
  background: #ffffff;
  color: #777777;
  border: 1px solid #dddddd;
  padding: 0px 18px;
  display: inline-block;
  line-height: 28px;
  border-radius: 3px;
  margin-bottom: 22px;
}

.l_news_item h4 {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #222222;
  line-height: 30px;
  margin-bottom: 15px;
}

.l_news_item p {
  margin-bottom: 22px;
}

.l_news_item .post_view a {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #aaaaaa;
  margin-right: 20px;
}

.l_news_item .post_view a i {
  padding-right: 6px;
}

.l_news_item:hover {
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}

.l_news_item:hover .date {
  background-image: -moz-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -webkit-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  background-image: -ms-linear-gradient(0deg, #bc2bff 0%, #7249fb 100%);
  color: #fff;
  border-color: #f9f9ff;
}

.l_news_item:hover h4 {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #bc2bff),
    color-stop(0.15, #bc2bff),
    color-stop(0.3, #7249fb),
    color-stop(0.45, #7249fb),
    color-stop(0.6, #7249fb),
    color-stop(0.75, #7249fb),
    color-stop(0.9, #7249fb),
    color-stop(1, #7249fb)
  );
  background-image: gradient(
    linear,
    left top,
    right top,
    color-stop(0, #bc2bff),
    color-stop(0.15, #bc2bff),
    color-stop(0.3, #7249fb),
    color-stop(0.45, #7249fb),
    color-stop(0.6, #7249fb),
    color-stop(0.75, #7249fb),
    color-stop(0.9, #7249fb),
    color-stop(1, #7249fb)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.abcd-item:hover a {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #bc2bff),
    color-stop(0.15, #bc2bff),
    color-stop(0.3, #7249fb),
    color-stop(0.45, #7249fb),
    color-stop(0.6, #7249fb),
    color-stop(0.75, #7249fb),
    color-stop(0.9, #7249fb),
    color-stop(1, #7249fb)
  );
  background-image: gradient(
    linear,
    left top,
    right top,
    color-stop(0, #bc2bff),
    color-stop(0.15, #bc2bff),
    color-stop(0.3, #7249fb),
    color-stop(0.45, #7249fb),
    color-stop(0.6, #7249fb),
    color-stop(0.75, #7249fb),
    color-stop(0.9, #7249fb),
    color-stop(1, #7249fb)
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.abcd-item:hover a {
  background-image: -moz-linear-gradient(
    0deg,
    #bc2bff 0%,
    #7249fb 100%
  ) !important;
  background-image: -webkit-linear-gradient(
    0deg,
    #bc2bff 0%,
    #7249fb 100%
  ) !important;
  background-image: -ms-linear-gradient(
    0deg,
    #bc2bff 0%,
    #7249fb 100%
  ) !important;
  color: #fff !important;
  border-color: #f9f9ff !important;
}

/* End Home latest Blog Area css
============================================================================================ */

.UserRoleRadio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (min-width: 1025px) and (max-width: 1026px) {
  .main-container {
    height: 100% !important;
  }
}

@media (min-width: 1224px) {
  .col-lg-3 {
    width: 20% !important;
  }
}

/* scroll bar */
.scroll-Style::-webkit-scrollbar {
  width: 5px;
  /* background-color: #CDD2AC; */
}
.scroll-Style::-webkit-scrollbar {
  background-color: grey;
  border-radius: 10px;
  /* box-shadow:0px 0px 6px 2px rgba(0, 0, 0, 0.5) inset; */
}

/* input file design */
.label input[type="file"] {
  display: none;
}
.label span {
  cursor: pointer;
  background: linear-gradient(to right, #00c6ff, #0072ff);
  border-radius: 1rem;
  color: #fff;
  padding: 10px 20px;
}
.label span:hover {
  border-color: #09f;
  background: linear-gradient(to right, #0072ff, #00c6ff);
}

.check-label {
  font-size: medium;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.area-check {
  margin-top: 8rem;
}
.complain-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-check {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.filter-check > div {
  display: flex;
  align-items: center;
}
.filter-check > div > div {
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
.filter-btn {
  display: flex;
}

/* check box styling */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: rgb(186, 223, 19);
  transition: all 0.3s;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #47da99;
  animation: pop 0.5s;
  animation-direction: alternate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

/* //compalin cards */
.heading {
  width: -webkit-fill-available;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
